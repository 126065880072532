// eslint-disable-next-line import/prefer-default-export
export const german = {
  default: {
    dashboard: 'Dashboard',
    'leads-talents': 'Leads / Talents',
    default: 'Standard',
    accountsAndPermissions: 'Konten und Berechtigungen',
    analytics: 'Analytik',
    userProfile: 'Benutzerprofil',
    security: 'Sicherheit',
    widget: 'Widget',
    statistics: 'Statistiken',
    data: 'Daten',
    chart: 'Diagramm',
    user: 'Benutzer',
    userSecurity: 'Benutzersicherheit',
    application: 'Anwendung',
    users: 'Benutzer',
    'social-profile': 'Soziales Profil',
    'account-profile': 'Kontoprofil',
    profile: 'Profil',
    cards: 'Karten',
    list: 'Liste',
    style: 'Stil',
    customer: 'Kunde',
    orders: 'Aufträge',
    customers: 'Kunden/Reseller',
    'customer-list': 'Kundenliste',
    'order-list': 'Bestellliste',
    'create-invoice': 'Rechnung erstellen',
    'order-details': 'Bestelldetails',
    product: 'Produkt',
    'product-review': 'Produktbewertung',
    chat: 'Plaudern',
    mail: 'Post',
    contact: 'Kontakt',
    calendar: 'Kalender',
    'e-commerce': 'E-Commerce',
    products: 'Produkte',
    'product-details': 'Produktdetails',
    'product-list': 'Produktliste',
    checkout: 'Auschecken',

    forms: 'Formen',
    components: 'Komponenten',
    autocomplete: 'Automatisch vervollständigen',
    button: 'Taste',
    checkbox: 'Ankreuzkästchen',
    'date-time': 'Datum und Uhrzeit',
    radio: 'Radio',
    slider: 'Schieberegler',
    switch: 'Wechseln',
    'text-field': 'Textfeld',
    plugins: 'Plugins',
    mask: 'Maske',
    clipboard: 'Zwischenablage',
    recaptcha: 'reCaptcha',
    'wysiwug-editor': 'WYSIWYG-Editor',
    modal: 'Modal',
    tooltip: 'Tooltip',
    table: 'Tabelle',
    'table-basic': 'Grundlegende Tabelle',
    'table-dense': 'Dichte Tabelle',
    'table-enhanced': 'Verbesserte Tabellen',
    'table-data': 'Daten-Tabelle',
    'table-customized': 'Angepasste Tabelle',
    'table-sticky-header': 'Festes Header',
    'table-collapse': 'Tabelle reduzieren',
    charts: 'Charts',
    apexchart: 'Apex-Charts',
    'organization-chart': 'Organisationsdiagramm',
    'forms-validation': 'Formularvalidierung',
    'forms-wizard': 'Formular-Assistent',
    layouts: 'Layouts',
    'multi-column-forms': 'Mehrspaltige Formulare',
    'action-bar': 'Aktionsleiste',
    'sticky-action-bar': 'Haftende Aktionsleiste',

    'ui-element': 'UI-Element',
    basic: 'Grundlegend',
    'basic-caption': '8+ Grundkomponenten',
    accordion: 'Akkordeon',
    avatar: 'Avatar',
    badges: 'Abzeichen',
    breadcrumb: 'Breadcrumb',
    chip: 'Chip',
    tabs: 'Tabs',
    advance: 'Voraus',
    alert: 'Alarm',
    dialog: 'Dialog',
    pagination: 'Seitennummerierung',
    progress: 'Fortschritt',
    rating: 'Bewertung',
    snackbar: 'Snackbar',
    speeddial: 'Wählrad',
    timeline: 'Zeitleiste',
    'toggle-button': 'Umschalttaste',
    treeview: 'Baumansicht',

    pages: 'Seiten',
    'pages-caption': 'Vorgefertigte Seiten',
    authentication: 'Authentifizierung',
    login: 'Anmeldung',
    register: 'Registrieren',
    'forgot-password': 'Passwort vergessen',
    'check-mail': 'Post prüfen',
    'reset-password': 'Passwort zurücksetzen',
    'code-verification': 'Code-Überprüfung',
    pricing: 'Preisgestaltung',
    price: 'Preis',
    maintenance: 'Wartung',
    'error-404': 'Fehler 404',
    'coming-soon': 'Demnächst',
    'under-construction': 'Im Bau',
    landing: 'Landung',
    'contact-us': 'Kontaktiere uns',
    faqs: 'FAQ',
    'privacy-policy': 'Datenschutz-Bestimmungen',

    utilities: 'Dienstprogramme',
    typography: 'Typografie',
    color: 'Farbe',
    shadow: 'Schatten',
    icons: 'Symbole',
    'tabler-icons': 'Tabler-Symbole',
    'material-icons': 'Material Symbole',
    animation: 'Animation',
    grid: 'Gitter',

    others: 'Andere',
    'menu-level': 'Menüebenen',
    level: 'Ebene',
    'menu-level-subtitle': 'Untertitel-Ebenen',
    'menu-level-subtitle-caption': 'Legende reduzieren',
    'menu-level-subtitle-item': 'Untertitel-Element',
    'menu-level-subtitle-collapse': 'Untertitel reduzieren',
    'menu-level-subtitle-sub-item': 'Untertitel-Unterelement',
    'disabled-menu': 'Deaktiviertes Menü',
    'oval-chip-menu': 'Ovale Chip-Karte',
    coded: 'Codiert',
    c: 'C',
    outlined: 'Skizziert',
    metaDashboard: 'Meta',
    'sample-page': 'Beispiel Seite',
    documentation: 'Dokumentation',
    roadmap: 'Straßenkarte',

    title: 'Multi-Sprache',
    home: 'Zuhause',
    change: 'Sprache ändern',
    'automatic-mail-msg':
      'Die von Dir vorgenommene Status Änderung sendet eine automatische Mail an den Kunden. Bist du sicher das du fortfahren möchtest?',
    'no-mail-address-was-provided':
      'Es wurde keine E-Mail-Adresse angegeben. Die automatischen Transaktions-E-Mails können nicht versendet werden.',
    'leads-per-month': 'Leads pro Monat',
    'leads-per-campaign': 'Leads pro Kampagne',
    'open-monthly-orders': 'Offene monatliche Bestellungen',
    'monthly-orders': 'Monatliche Bestellungen',
    'active-orders': 'Aktive Bestellungen',
    'customer-details': 'Kundendetails',
    edit: 'Bearbeiten',
    save: 'Speichern',
    'order-not-found': 'Bestellung nicht gefunden',
    'view-edit-order': 'Bestellung anzeigen / bearbeiten',
    'order-type': 'Bestellart',
    gender: 'Geschlecht',
    place: 'Ort',
    portal: 'Portal',
    'keywords-comma': 'Stichwörter (durch Komma getrennt)',
    comments: 'Kommentare',
    metaCampaign: 'Metakampagne',
    'budgets-invoice': 'Budgets und Rechnung',
    runtime: 'Laufzeit',
    'invoice-files': 'Rechnungsdateien',
    preview: 'Vorschau',
    'job-details': 'Jobdetails',
    'preview-all-link': 'Vorschau-Link für alles',
    'uploaded-files': 'Hochgeladene Dateien',
    'image-assets': 'Bildmaterial',
    'video-assets': 'Videomaterial',
    'edited-successfully': 'Erfolgreich bearbeitet',
    'customer-type': 'Kundentyp',
    assignee: 'Zuständiger',
    'job-title': 'Berufsbezeichnung',
    status: 'Status',
    'job-ad-link': 'Stellenanzeigenlink',
    'external-import-id': 'Externe Import-ID',
    keywords: 'Stichwörter',
    budget: 'Budget',
    'which-transactional': 'Welche transaktionale E-Mail möchten Sie senden?',
    template: 'Vorlage',
    send: 'Senden',
    exit: 'Beenden',
    'yearly-turnover': 'Jahresumsatz',
    'hide-all': 'Alle ausblenden',
    'view-All': 'Alle anzeigen',
    'no-open-orders': 'Keine offenen Bestellungen',
    order: 'Bestellung',
    'new-order': 'Neue Bestellung',
    'order-title': 'Bestellungstitel',
    location: 'Ort',
    leads: 'Leads',
    'your-leads': 'Ihre Leads',
    name: 'Name',
    date: 'Datum',
    'coming-soon-until':
      'Kommt bald - bis dahin, wenn Sie eine neue Bestellung aufgeben möchten, kontaktieren Sie uns bitte unter',
    'no-orders-yet':
      'Noch keine Bestellungen. Möchten Sie einige aufgeben? Bitte kontaktieren Sie uns unter',
    'create-new-order': 'Neue Bestellung erstellen',
    create: 'Erstellen',
    'new-campaign': 'Neue Kampagne',
    'customer-image-assets': 'Kundebildmaterial',
    previews: 'Vorschauen',
    'created-successfully': 'Erfolgreich erstellt',
    'order-preview-approval': 'Bestellvorschau Genehmigung',
    'order-approved': 'Bestellung {jobName} wurde genehmigt!',
    reseller: 'Wiederverkäufer',
    'date-created': 'Erstellungsdatum',
    'total-leads': 'Gesamtleads',
    'created-at': 'Erstellt am',
    'first-name': 'Vorname',
    'last-name': 'Nachname',
    phone: 'Telefon',
    email: 'E-Mail',
    channel: 'Kanal',
    type: 'Typ',
    'phone-number': 'Telefonnummer',
    address: 'Adresse',
    emails: 'E-Mails',
    'business-information-not-found':
      'Unternehmensinformationen nicht gefunden. Sind Sie sicher, dass Sie den Kunden zum entsprechenden App-Benutzer hinzugefügt haben?',
    'account-setting': 'Kontoeinstellung',
    'business-information': 'Unternehmensinformationen',
    'upload-image': 'Bild hochladen',
    'upload-change-image': 'Bild hochladen oder ändern',
    'change-details': 'Details ändern',
    close: 'Schließen',
    'change-password': 'Passwort ändern',
    'confirm-new-password': 'Neues Passwort bestätigen',
    'new-password': 'Neues Passwort',
    'current-password': 'Aktuelles Passwort',
    'not-admin':
      'Hoppla! Es sieht so aus, als wären die universellen Berechtigungen noch nicht vom Administrator festgelegt worden!',
    'customer-permissions': 'Kundenberechtigungen',
    'customer-name': 'Kundenname',
    'connected-reseller': 'Verbundener Wiederverkäufer',
    'auto-transactional-emails': 'Automatische transaktionale E-Mails',
    'phone-numbers': 'Telefonnummern',
    website: 'Webseite',
    'logo-not-provided': 'Logo nicht bereitgestellt',
    'set-as-invoice': 'Als Rechnung festlegen',
    'set-as-preview': 'Als Vorschau festlegen',
    'set-as-default': 'Als Standard festlegen',
    add: 'Hinzufügen',
    'check-box-dashboard':
      'Aktivieren Sie das Kontrollkästchen, um das Dashboard in Ihrer Seitenleiste zugänglich zu machen',
    'check-box-orders':
      'Aktivieren Sie das Kontrollkästchen, um Bestellungen und deren Inhalt in Ihrer Seitenleiste zugänglich zu machen',
    'check-box-customers':
      'Aktivieren Sie das Kontrollkästchen, um Kunden in Ihrer Seitenleiste zugänglich zu machen',
    'check-box-campaigns':
      'Aktivieren Sie das Kontrollkästchen, um Kampagnen in Ihrer Seitenleiste zugänglich zu machen',
    'check-box-leads':
      'Aktivieren Sie das Kontrollkästchen, um Leads und Talente in Ihrer Seitenleiste zugänglich zu machen',
    'leads-n-talents': 'Leads und Talente',
    'orders-table': 'Bestellungen-Tabelle',
    'orders-details': 'Bestellungen-Details',
    'customers-table': 'Kunden-Tabelle',
    'customers-details': 'Kunden-Details',
    'campaigns-table': 'Kampagnen-Tabelle',
    'campaigns-details': 'Kampagnen-Details',
    'leads-n-talents-details': 'Leads und Talente Details',
    'please-press-edit':
      '.(Bitte drücken Sie die Schaltfläche "Bearbeiten", um die Benutzerberechtigungen zu bearbeiten)',
    'dashboard-accessible-user': 'Dashboard ist für diesen Benutzer zugänglich',
    'orders-for-user': 'Bestellungen und deren Inhalt sind für diesen Benutzer zugänglich',
    'customers-for-user': 'Kunden sind für diesen Benutzer zugänglich',
    'campaigns-for-user': 'Kampagnen sind für diesen Benutzer zugänglich',
    'leads-n-talents-for-user': 'Leads und Talente sind für diesen Benutzer zugänglich',
    enabled: 'Aktiviert',
    campaigns: 'Kampagnen',
    'create-new-customer': 'Neuen Kunden erstellen',
    'contact-details': 'Kontaktdetails',
    event: 'Ereignis',
    action: 'Aktion',
    cancel: 'Abbrechen',
    'updated-successfully': 'Erfolgreich aktualisiert',
    'go-homepage': 'Zur Startseite gehen',
    'page-not-found': 'Seite nicht gefunden',
    platform: 'Plattform',
    'full-name': 'Vollständiger Name',
    'monthly-turnover': 'Monatlicher Umsatz',
    'total-orders': 'Gesamtbestellungen',
    'performance-kpis': 'Leistungs-KPIs',
    'adsets-kpis': 'Ad sets KPIs',
    'merge-campaigns': 'Kampagnen zusammenführen',
    merge: 'Verschmelzen',
    'active-campaign': 'Aktive Kampagne',
    'archived-campaign': 'Archivierte Kampagne',
    campaign: 'Kampagne',
    'campaigns-merge-success': 'Kampagnen wurden erfolgreich zusammengeführt',
    'Open Campaign': 'Offene Kampagne',
    female: 'Weiblich',
    male: 'Männlich',
    unknown: 'Unbekannt',
    facebook: 'Facebook',
    instagram: 'Instagram',
    desktop: 'Desktop',
    'android-smartphone': 'Android Smartphone',
    'android-tablet': 'Android Tablet',
    iphone: 'iPhone',
    ipad: 'iPad',
    ipod: 'iPod',
    other: 'Andere',
    'device-platform': 'Geräteplattform',
    frequency: 'Häufigkeit',
    uniqueClicks: 'Einzigartige Klicks',
    impressions: 'Impressionen',
    clicks: 'Klicks',
    ctr: 'Klickrate',
    'fb-vs-instagram': 'Linkklicks - Facebook vs Instagram',
    'link-clicks': 'Linkklicks',
    'back-to-campaigns': 'Zurück zu Kampagnen',
    'unique-clicks': 'Einzigartige Klicks',
    accounts: 'Konten',
    permissions: 'Berechtigungen',
    clearAll: 'Alles löschen',
    'user-types': 'Benutzer Rollen',
    'user-details': 'Benutzerdetails',
    'connected-customer': 'Verbundener Kunde',
    'no-connected-customer': 'Warnung: App-Benutzer ist mit keinem Kunden verbunden',
    'asana-id': 'Asana ID',
    'open-orders': 'Offene Bestellungen',
    'todo-orders': 'To-Do Bestellungen',
    'in-progress-orders': 'In Bearbeitung Bestellungen',
    'waiting-for-approval-orders': 'Warte auf Genehmigung Bestellungen',
    'app-user-name': 'App Benutzername',
    'typebot-campaign': 'Typebot Kampagne',
    link: 'Link',
    'leads-count': 'Leads Anzahl',
    'existing-typebot-campaign-path': 'Vorhandener Typebot Kampagnenpfad',
    'edit-url': 'Bearbeiten URL',
    'page-name': 'Seitenname',
    'ad-account-id': 'Anzeigenkonto ID',
    'connected-app-user': 'Verbundener App Benutzer',
    leadgenform: 'Leadgenform',
    'original-meta-campaign': 'Ursprüngliche Metakampagne',
  },
}
