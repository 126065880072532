export const english = {
  default: {
    dashboard: 'Dashboard',
    'leads-talents': 'Leads / Talents',
    accountsAndPermissions: 'Accounts / Permissions',
    default: 'Default',
    analytics: 'Analytics',
    user: 'User',
    userProfile: 'User Profile',
    userSecurity: 'User Security',
    widget: 'Widget',
    statistics: 'Statistics',
    data: 'Data',
    chart: 'Chart',
    application: 'Application',
    users: 'Users',
    'social-profile': 'Social Profile',
    'account-profile': 'Account Profile',
    profile: 'Profile',
    cards: 'Cards',
    list: 'List',
    style: 'Style',
    customer: 'Customer',
    orders: 'Orders',
    security: 'Security',
    metaDashboard: 'Meta',
    'customer-list': 'Customer List',
    'order-list': 'Order List',
    'create-invoice': 'Create Invoice',
    'order-details': 'Order Details',
    product: 'Product',
    'product-review': 'Product Review',
    chat: 'Chat',
    mail: 'Mail',
    contact: 'Contact',
    calendar: 'Calendar',
    'e-commerce': 'E-commerce',
    products: 'Products',
    'product-details': 'Product Details',
    'product-list': 'Product List',
    checkout: 'Checkout',

    forms: 'Forms',
    components: 'Components',
    autocomplete: 'Autocomplete',
    button: 'Button',
    checkbox: 'Checkbox',
    'date-time': 'Date & Time',
    radio: 'Radio',
    slider: 'Slider',
    switch: 'Switch',
    'text-field': 'Text Field',
    plugins: 'Plugins',
    mask: 'Mask',
    clipboard: 'Clipboard',
    recaptcha: 'reCaptcha',
    'wysiwug-editor': 'Wysiwug Editor',
    modal: 'Modal',
    tooltip: 'Tooltip',
    table: 'Table',
    'table-basic': 'Basic Table',
    'table-dense': 'Dense Table',
    'table-enhanced': 'Enhanced Tables',
    'table-data': 'Data Table',
    'table-customized': 'Custom Table',
    'table-sticky-header': 'Fixed Header',
    'table-collapse': 'Collapse Table',
    charts: 'Charts',
    apexchart: 'Apexchart',
    'organization-chart': 'Organization Chart',
    'forms-validation': 'Forms Validation',
    'forms-wizard': 'Forms Wizard',
    layouts: 'Layouts',
    'multi-column-forms': 'Multi Column Forms',
    'action-bar': 'Action Bar',
    'sticky-action-bar': 'Sticky Action Bar',

    'ui-element': 'UI Element',
    basic: 'Basic',
    'basic-caption': '8+ Basic Components',
    accordion: 'Accordion',
    avatar: 'Avatar',
    badges: 'Badges',
    breadcrumb: 'Breadcrumb',
    chip: 'Chip',
    tabs: 'Tabs',

    advance: 'Advance',
    alert: 'Alert',
    dialog: 'Dialog',
    pagination: 'Pagination',
    progress: 'Progress',
    rating: 'Rating',
    snackbar: 'Snackbar',
    skeleton: 'Skeleton',
    speeddial: 'Speeddial',
    timeline: 'Timeline',
    'toggle-button': 'Toggle Button',
    treeview: 'Treeview',

    pages: 'Pages',
    'pages-caption': 'Prebuild Pages',
    authentication: 'Authentication',
    login: 'Login',
    register: 'Register',
    'forgot-password': 'Forgot Password',
    'check-mail': 'Check Mail',
    'reset-password': 'Reset Password',
    'code-verification': 'Code Verification',
    pricing: 'Pricing',
    price: 'Price',
    maintenance: 'Maintenance',
    'error-404': 'Error 404',
    'coming-soon': 'Coming Soon',
    'under-construction': 'Under Construction',
    landing: 'Landing',
    'contact-us': 'Contact US',
    faqs: 'FAQs',
    'privacy-policy': 'Privacy Policy',
    customers: 'Customers / Resellers',
    utilities: 'Utilities',
    typography: 'Typography',
    color: 'Color',
    shadow: 'Shadow',
    icons: 'Icons',
    'tabler-icons': 'Tabler Icons',
    'material-icons': 'Material Icons',
    animation: 'Animation',
    grid: 'Grid',

    others: 'Others',
    'menu-level': 'Menu Levels',
    level: 'Level',
    'menu-level-subtitle': 'Sub Caption Levels',
    'menu-level-subtitle-caption': 'Caption Collapse',
    'menu-level-subtitle-item': 'Caption Item',
    'menu-level-subtitle-collapse': 'Sub Collapse Caption',
    'menu-level-subtitle-sub-item': 'Sub Item Caption',
    'disabled-menu': 'Disabled Menu',
    'oval-chip-menu': 'Oval Chip',
    coded: 'Coded',
    c: 'C',
    outlined: 'Outlined',

    'sample-page': 'Sample Page',
    documentation: 'Documentation',
    roadmap: 'Roadmap',

    title: 'Multi Language',
    home: 'Home',
    change: 'Change Language',
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiatnulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollitanim id est laborum.',
    'automatic-mail-msg':
      'This status change will trigger an automatic mail to the customer. Are you sure you want to proceed?',
    'no-mail-address-was-provided':
      'No mail address was provided. The automatic transactional emails cannot be sent out.',
    'leads-per-month': 'Leads per month',
    'leads-per-campaign': 'Leads per campaign',
    'open-monthly-orders': 'Open Monthly Orders',
    'monthly-orders': 'Monthly Orders',
    'active-orders': 'Active Orders',
    'customer-details': 'Customer Details',
    edit: 'Edit',
    save: 'Save',
    'order-not-found': 'Order not found',
    'view-edit-order': 'View / Edit Order',
    'order-type': 'Order type',
    gender: 'Gender',
    place: 'Place',
    portal: 'Portal',
    'keywords-comma': 'Keywords (Separated by comma)',
    comments: 'Comments',
    metaCampaign: 'Meta Campaign',
    'budgets-invoice': 'Budgets and Invoice',
    runtime: 'Runtime',
    'invoice-files': 'Invoice Files',
    preview: 'Preview',
    'job-details': 'Job Details',
    'preview-all-link': 'Preview All Link',
    'uploaded-files': 'Uploaded Files',
    'image-assets': 'Image assets',
    'video-assets': 'Video assets',
    'edited-successfully': 'Edited successfully',
    'customer-type': 'Customer type',
    assignee: 'Assignee',
    'job-title': 'Job title',
    status: 'Status',
    'job-ad-link': 'Job Ad link',
    'external-import-id': 'External import id',
    keywords: 'Keywords',
    budget: 'Budget',
    // ---
    'which-transactional': 'Which transactional mail would you like to send?',
    template: 'Template',
    send: 'Send',
    exit: 'Exit',
    'yearly-turnover': 'Yearly Turnover',
    'hide-all': 'Hide all',
    'view-All': 'View All',
    'no-open-orders': 'No open Orders',
    order: 'Order',
    'new-order': 'New order',
    'order-title': 'Order title',
    location: 'Location',
    leads: 'Leads',
    'your-leads': 'Your leads',
    name: 'Name',
    date: 'Date',
    'coming-soon-until':
      'Coming soon - until then, if you would like to place a new order please contact us at',
    'no-orders-yet': 'No orders yet. Would you like to place some? Please contact us at',
    'create-new-order': 'Create New Order',
    create: 'Create',
    'new-campaign': 'New Campaign',
    'customer-image-assets': 'Customer image assets',
    previews: 'Previews',
    'created-successfully': 'Created successfully',
    'order-preview-approval': 'Order Preview Approval',
    'order-approved': 'Order {jobName} has been approved!',
    reseller: 'Reseller',
    'date-created': 'Date created',
    'total-leads': 'Total leads',
    'created-at': 'Created At',
    'first-name': 'First Name',
    'last-name': 'Last Name',
    phone: 'Phone',
    email: 'Email',
    channel: 'Channel',
    type: 'Type',
    'phone-number': 'Phone number',
    address: 'Address',
    emails: 'Emails',
    'business-information-not-found':
      'Business information not found. Are you sure that you have added customer to the related App user?',
    'account-setting': 'Account Setting',
    'business-information': 'Business Information',
    'upload-image': 'Upload Image',
    'upload-change-image': 'Upload or change the image',
    'change-details': 'Change Details',
    close: 'Close',
    'change-password': 'Change Password',
    'confirm-new-password': 'Confirm new password',
    'new-password': 'New password',
    'current-password': 'Current password',
    'not-admin': 'Oops! Looks like the universal permissions are not set yet by Admin!',
    'customer-permissions': 'Customer Permissions',
    'customer-name': 'Customer name',
    'connected-reseller': 'Connected reseller',
    'auto-transactional-emails': 'Automatic transactional emails',
    'phone-numbers': 'Phone Numbers',
    website: 'Website',
    'logo-not-provided': 'Logo not provided',
    'set-as-invoice': 'Set as invoice',
    'set-as-preview': 'Set as preview',
    'set-as-default': 'Set as default',
    add: 'Add',
    'check-box-dashboard': 'Check the box to make the Dashboard accessible on your sidebar',
    'check-box-orders':
      'Check the box to make the Orders and its content accessible on your sidebar',
    'check-box-customers': 'Check the box to make the Customers accessible on your sidebar',
    'check-box-campaigns': 'Check the box to make Campaigns accessible on your sidebar',
    'check-box-leads': 'Check the box to make Leads and Talents accessible on your sidebar',
    'leads-n-talents': 'Leads and Talents',
    'orders-table': 'Orders Table',
    'orders-details': 'Orders Details',
    'customers-table': 'Customers Table',
    'customers-details': 'Customers Details',
    'campaigns-table': 'Campaigns Table',
    'campaigns-details': 'Campaigns Details',
    'leads-n-talents-details': 'Leads and Talents Details',
    'please-press-edit': '.(Please press the "Edit" button to edit the user permissions)',
    'dashboard-accessible-user': 'Dashboard is accessible for this user',
    'orders-for-user': 'Orders and its content is accessible for this user',
    'customers-for-user': 'Customers is accessible for this user',
    'campaigns-for-user': 'Campaigns is accessible for this user',
    'leads-n-talents-for-user': 'Leads and Talents is accessible for this user',
    enabled: 'Enabled',
    campaigns: 'Campaigns',
    'create-new-customer': 'Create New Customer',
    'contact-details': 'Contact Details',
    event: 'Event',
    action: 'Action',
    cancel: 'Cancel',
    'updated-successfully': 'Updated successfully',
    'go-homepage': 'Go to homepage',
    'page-not-found': 'Page not found',
    platform: 'Platform',
    'full-name': 'Full Name',
    'monthly-turnover': 'Monthly Turnover',
    'total-orders': 'Total Orders',
    'performance-kpis': 'Performance KPIs',
    'adsets-kpis': 'Ad sets KPIs',
    'merge-campaigns': 'Merge campaigns',
    merge: 'Merge',
    'active-campaign': 'Active campaign',
    'archived-campaign': 'Archived campaign',
    campaign: 'Campaign',
    'campaigns-merge-success': 'Campaigns were merged successfully',
    female: 'Female',
    male: 'Male',
    unknown: 'Unknown',
    facebook: 'Facebook',
    instagram: 'Instagram',
    desktop: 'Desktop',
    'android-smartphone': 'Android Smartphone',
    'android-tablet': 'Android Tablet',
    iphone: 'iphone',
    ipad: 'Ipad',
    ipod: 'Ipod',
    other: 'Other',
    'device-platform': 'Device Platform',
    frequency: 'Frequency',
    uniqueClicks: 'Unique Clicks',
    impressions: 'Impressions',
    clicks: 'Clicks',
    ctr: 'CTR',
    'fb-vs-instagram': 'Link Clicks - Facebook vs Instagram',
    'link-clicks': 'Link Clicks',
    'back-to-campaigns': 'Go back to campaigns',
    'unique-clicks': 'Unique clicks',
    accounts: 'Accounts',
    permissions: 'Permissions',
    clearAll: 'Clear all',
    'user-types': 'User types',
    'user-details': 'User details',
    'connected-customer': 'Connected customer',
    'no-connected-customer': 'Warning: App user is not connected to any customer',
    'asana-id': 'Asana ID',
    'open-orders': 'Open Orders',
    'todo-orders': 'Todo Orders',
    'in-progress-orders': 'In Progress Orders',
    'waiting-for-approval-orders': 'Waiting for Approval Orders',
    'app-user-name': 'App User Name',
    'typebot-campaign': 'Typebot Campaign',
    link: 'Link',
    'leads-count': 'Leads Count',
    'existing-typebot-campaign-path': 'Existing Typebot Campaign Path',
    'edit-url': 'Edit URL',
    'page-name': 'Page Name',
    'ad-account-id': 'Ad Account ID',
    'connected-app-user': 'Connected App User',
    leadgenform: 'Leadgen Form',
    'original-meta-campaign': 'Original Meta Campaign',
  },
}
