import React, { useEffect, useMemo } from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import dayjs from 'dayjs'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import EnhancedTableHead from './EnhancedTableHeader'
import RowRender from './RowRender'
import { OrderEnhancedTableToolbar } from './EnhancedTableToolbar/OrderEnhancedTableToolbar'
import { generateDefaultDense, getComparator, stableSort } from '../../../utils/tools'
import { useDispatch, useSelector } from '../../../store/store'
import { removeSelected, setSelected } from '../../../store/slices/table'
import { clearFilters } from '../../../store/slices/filters/filters'
import Loading from '../../../ui-component/loading/Loading'

interface TableRenderProps {
  data?: unknown[]
  isLoading: boolean
  checkbox?: boolean
  toolbar?: boolean
  pagination?: boolean
  densePadding?: boolean
  defaultOrderBy: string
  emptyPlaceholder?: React.ReactNode
  AnteFilterBtn?: JSX.Element
  page: number
  rowsPerPage: number
  setRowsPerPage: (page: number) => void
  rowsPerPageItemName?: string
  setPage: (page: number) => void
  isOverview?: boolean
  totalCount: number
}
export type Order = 'asc' | 'desc'

const rowFilter = (rows: string[] | never[], filters) =>
  rows.filter((item: any) => {
    const hasItem: boolean[] = []
    Object.entries(filters).forEach((filter: any) => {
      if (filter[0] === 'search') return

      if (['createdTime', 'createdAt'].includes(filter[0])) {
        const filterDate = dayjs(filter[1]).format('DD/MM/YYYY').toString()
        const rowDate = dayjs(item[filter[0]]).format('DD/MM/YYYY').toString()

        return hasItem.push(filterDate === rowDate)
      }

      if (filter[0] === 'resellers') {
        return hasItem.push(item.connectedReseller === filter[1])
      }
      if (filter[0] === 'email') {
        const emails = JSON.parse(item.emails)
        return hasItem.push(emails.includes(filter[1]))
      }
      hasItem.push(item[filter[0]]?.includes(filter[1]))
    })

    return !hasItem.includes(false)
  })

const handleFilters = (rows: any[], filters: { search: string; searchBy: string }) => {
  const hasSearch =
    filters?.search &&
    rowFilter(rows, filters).filter((item: any) =>
      Object.values(item)
        .map((item: any) => item?.toString())
        .find((item) => item?.toLowerCase()?.includes(filters?.search?.toLowerCase()))
    )

  return hasSearch || rowFilter(rows, filters)
}

export default function OrderTableRender({
  data = [],
  isLoading = false,
  checkbox = false,
  pagination = false,
  densePadding = false,
  defaultOrderBy = '',
  emptyPlaceholder = null,
  isOverview = false,
  totalCount,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
}: TableRenderProps) {
  const densePaddingItemName = 'orderTableDensePadding'
  const rowsPerPageItemName = 'orderTableRowsPerPage'

  const defaultDense = generateDefaultDense(densePaddingItemName)

  const { selected } = useSelector((state) => state.table)
  const { filters } = useSelector((state) => state.filters)
  const { permissions } = useSelector((state) => state.user)

  const [order, setOrder] = React.useState<Order>('desc')
  const [orderBy, setOrderBy] = React.useState<string>(defaultOrderBy)
  const [rows, setRows] = React.useState<unknown[] | never[]>([])
  const [dense, setDense] = React.useState(isOverview || defaultDense)

  useEffect(() => {
    if (totalCount <= rowsPerPage) {
      setPage(0)
    }
  }, [totalCount, setPage, rowsPerPage])

  const dispatch = useDispatch()

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id)
      dispatch(setSelected(newSelected))
      return
    }

    dispatch(removeSelected())
  }

  const handleChangePage = (_e, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPageCB = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
      localStorage.setItem(rowsPerPageItemName, String(event.target.value))
    },
    [rowsPerPageItemName, setPage, setRowsPerPage]
  )

  const handleChangeDenseCB = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDense(event.target.checked)
      localStorage.setItem(densePaddingItemName, String(event.target.checked))
    },
    [densePaddingItemName]
  )

  const isSelected = (id: string) => {
    return selected.indexOf(id) !== -1
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalCount) : 0

  const filteredRows = useMemo(() => handleFilters(rows, filters), [rows, filters])

  const visibleRows = useMemo(
    () => rows && stableSort(filteredRows, getComparator(order, orderBy)),
    [rows, filteredRows, order, orderBy]
  )

  useEffect(() => {
    if (data) {
      dispatch(removeSelected()) // It cleans selection on table render
      setRows(data)
    }
  }, [data, dispatch, permissions?.customersDetailsFields])

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [dispatch])

  const hasPermission = permissions?.leadsAndTalents
  !hasPermission && null

  return (
    <Box sx={{ width: '100%' }}>
      {!isOverview && <OrderEnhancedTableToolbar />}
      {isLoading ? (
        <Loading />
      ) : (
        <TableContainer sx={{ overflowX: !data.length && emptyPlaceholder ? 'hidden' : null }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              checkbox={checkbox}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length || 0}
            />

            <TableBody>
              <RowRender rows={visibleRows} isSelected={isSelected} checkbox={checkbox} />
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          {!data.length && emptyPlaceholder}
        </TableContainer>
      )}
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, 200]}
          component="div"
          count={totalCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPageCB}
        />
      )}
      {densePadding && !isOverview && (
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDenseCB} />}
          label="Dense padding"
        />
      )}
    </Box>
  )
}
