import useSWR, { SWRResponse } from 'swr'
import { forwardRef, useEffect, useMemo, useState } from 'react'
import commonCodebase from 'common-recruspin-codebase'
import { Dialog, Link, Slide, Typography } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { FormattedMessage } from 'react-intl'
import BoxWrapper from '../../components/BoxWrapper'
import { TableData, Torder } from '../../constants'
import axiosInstance from '../../utils/axiosInstance'
import { convertValuesToLabels, firstNameWithFirstLetterOfLastName } from '../../utils/tools'
import { useDispatch, useSelector } from '../../store/store'
import CreateOrder from './create/CreateOrder'
import CreateOrderDenied from './create/CreateOrderDenied'
import { dialogToggle } from '../../store/slices/dialog'
import { setTriggerOrdersRefetch } from '../../store/slices/general'
import useInternalUserPermissions from '../../hooks/useInternalUserPermissions'
import {
  setordersFilterQuerycustomerSearch,
  setOrdersFilterQueryParams,
} from '../../store/slices/filters/filters'
import OrderTableRender from './table'
import { OrdersFilterParams } from '../../store/slices/filters/type'

interface OrdersProps {
  isOverview?: boolean
  customerId?: string
  customerType?: string
}

const { statuses } = commonCodebase.orders

const Orders = ({ isOverview = false, customerId, customerType }: OrdersProps) => {
  const dispatch = useDispatch()
  useEffect(() => {
    const getInitialParams = (): OrdersFilterParams => {
      const params: OrdersFilterParams = {
        createdAt: undefined,
        assignee: undefined,
        reseller: undefined,
        customer: undefined,
      }
      if (isOverview) {
        if (customerType === 'partner') params.reseller = customerId
        else params.customer = customerId
      }

      return params
    }

    const initialParams = getInitialParams()

    dispatch(setOrdersFilterQueryParams(initialParams))
    dispatch(setordersFilterQuerycustomerSearch(undefined))
  }, [isOverview, customerType, customerId, dispatch])

  const [page, setPage] = useState(0)
  const defaultRowsPerPage = Number(localStorage.getItem('orderTableRowsPerPage')) || 5
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)

  const { ordersFilterQueryParams, ordersFilterQuerycustomerSearch: search } = useSelector(
    (state) => state.filters
  )

  const params = useMemo(
    () => ({
      page,
      rowsPerPage,
      search,
      ...ordersFilterQueryParams,
    }),
    [ordersFilterQueryParams, page, rowsPerPage, search]
  )
  const {
    data: fetchedData,
    isLoading: loading,
    mutate,
  }: SWRResponse = useSWR('/orders', (url) => axiosInstance.get(url, { params }))
  const data = fetchedData?.data?.orders
  const totalCount = fetchedData?.data?.totalCount

  // const { data: totalOrders } = useSWR('/total-orders-count', axiosInstance)
  // const totalCount = totalOrders?.data.data

  const { open } = useSelector((state) => state.dialog)
  const { isInternalUser } = useInternalUserPermissions()
  const { triggerOrdersRefetch } = useSelector((state) => state.general)

  useEffect(() => {
    mutate()
  }, [params, mutate])

  useEffect(() => {
    if (triggerOrdersRefetch) {
      mutate()
      dispatch(setTriggerOrdersRefetch(false))
    }
  }, [dispatch, mutate, triggerOrdersRefetch])

  const emptyPlaceholder = !isInternalUser ? (
    <Typography textAlign="center" pt="30px">
      <FormattedMessage id="no-orders-yet" />{' '}
      <Link href="mailto:ap_offers@aschenputtel.agency"> ap_offers@aschenputtel.agency</Link>
    </Typography>
  ) : null

  let rowsData: TableData[] | [] = []
  if (data) {
    rowsData = data?.map((order: Torder) => {
      const {
        id,
        customer,
        jobType,
        jobName,
        status,
        place,
        createdAt,
        assignee,
        channel,
        budget,
        runtime,
        price,
        previews,
      } = order
      return createData(
        id,
        customer?.id,
        customer?.name,
        customer?.logoUrl,
        customer?.type,
        jobType,
        jobName,
        convertValuesToLabels(statuses, status),
        place,
        createdAt,
        firstNameWithFirstLetterOfLastName(assignee),
        channel.join(', '),
        budget,
        runtime,
        price,
        customer?.emails,
        previews,
        customer?.connectedReseller?.name
      )
    })
  }

  const handleClose = () => dispatch(dialogToggle(false))

  return (
    <BoxWrapper height="100%">
      <OrderTableRender
        data={rowsData}
        totalCount={totalCount}
        defaultOrderBy={'createdAt'}
        toolbar
        pagination
        densePadding
        isLoading={loading}
        emptyPlaceholder={emptyPlaceholder}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        rowsPerPageItemName="orderTableRowsPerPage"
        isOverview={isOverview}
      />
      {isInternalUser && !isOverview ? (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <CreateOrder handleClose={handleClose} />
        </Dialog>
      ) : (
        <Dialog
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          fullWidth
          maxWidth="sm"
        >
          <CreateOrderDenied />
        </Dialog>
      )}
    </BoxWrapper>
  )
}
export default Orders

function createData(
  id: string,
  customerID: string,
  customerName: string,
  logoUrl: string,
  customerType: string,
  jobType: string,
  jobName: string,
  status: string,
  place: string,
  createdAt: string,
  assignee: string,
  channel: string,
  budget: string,
  runtime: string,
  price: string,
  customerEmails: string,
  previews: Record<string, string>[] = [],
  connectedReseller = ''
): TableData {
  return {
    id,
    customerID,
    customerName,
    customerType,
    jobType,
    jobName,
    logoUrl,
    status,
    place,
    createdAt,
    assignee,
    channel,
    budget,
    runtime,
    price,
    connectedReseller,
    customerEmails,
    previews,
  }
}
export const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})
